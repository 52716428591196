import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProductItem } from '@shared/interfaces/product.interface';
import { BehaviorSubject, map, Observable, of, Subject } from 'rxjs';
import { UpdateCartItemRequest } from 'src/domain/cart/update-cart-item-request';
import { CartItemResponse } from './cart.interface';
import { addToCart, getCart, updateItemQuantity } from './store/cart.actions';
import { selectCart, selectCartItem, selectCheckoutIsLoading, selectIsCartLoading } from './store/cart.selectors';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  public cart$ = this.store.select(selectCart).pipe();
  public isLoading$ = this.store.select(selectIsCartLoading);
  public checkoutIsLoading$ = this.store.select(selectCheckoutIsLoading);
  public openCart$ = new Subject<void>();
  public isCartOpen$ = new BehaviorSubject<boolean>(false);

  constructor(private readonly store: Store) {
    this.store.dispatch(getCart());
  }

  public getCart(): void {
    this.store.dispatch(getCart());
  }

  public addToCart(product: ProductItem, subscriptionId?: string): void {
    this.store.dispatch(addToCart({ product, subscriptionId }));
  }

  public updateItemQuantity(updateCartItemRequest: UpdateCartItemRequest): void {
    this.store.dispatch(updateItemQuantity({ updateCartItemRequest }));
  }

  public openCart(): void {
    this.openCart$.next();
  }

  public toggleCartState(isOpen: boolean): void {
    this.isCartOpen$.next(isOpen);
  }

  public getItem$(variantId: string): Observable<CartItemResponse | undefined> {
    return this.store.select(selectCartItem(variantId));
  }

  public itemCount$(variantId: string): Observable<number> {
    return this.getItem$(variantId).pipe(map((item) => item?.quantity ?? 0));
  }

  public subscriptionAlreadyAddedToCart$(variantId: string, selectedSubscription?: string): Observable<boolean> {
    return this.getItem$(variantId).pipe(
      map((item) => {
        if (item?.pickedSubscription && selectedSubscription) {
          return item?.pickedSubscription?.id === selectedSubscription;
        }
        return false;
      })
    );
  }

  public allItemsPutInCart$(product: ProductItem): Observable<boolean> {
    if (!product.inStock) {
      // When the product is not even in stock, it cannot be added to the cart.
      return of(false);
    }
    return this.itemCount$(product.variantId).pipe(map((count) => count >= product.quantityAvailable));
  }
}
