import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BottomSheetAndDialogRef } from '@shared/components/bottom-sheet/bottom-sheet-and-dialog-ref';
import { AvailableSubscription, ProductItem } from '@shared/interfaces/product.interface';
import { CartService } from '@shop/modules/cart/cart.service';
import { CartVM } from '@shop/modules/cart/cart.vm';
import { BehaviorSubject, distinctUntilKeyChanged, Observable, skip, Subject, switchMap, take, tap } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-add-to-cart',
  templateUrl: './add-to-cart.component.html',
  styleUrls: ['./add-to-cart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddToCartComponent implements OnInit, OnDestroy {
  @Input() public product!: ProductItem;
  @Input() public selectedSubscription: AvailableSubscription | null = null;
  @Input() public inverseButton = false;
  public readonly cart$: Observable<CartVM> = this.cartService.cart$;
  public addToCart$ = new Subject<void>();
  public addedToCart$ = new BehaviorSubject<boolean>(false);
  public readonly enableInventoryNotification = false;
  private readonly addToCartAnimationDebounce = 1000;
  private readonly dialogRef?: BottomSheetAndDialogRef;

  constructor(private readonly cartService: CartService) {}

  public get itemCount$(): Observable<number> {
    return this.cartService.itemCount$(this.product.variantId);
  }

  public get subscriptionAlreadyAddedToCart$(): Observable<boolean> {
    return this.cartService.subscriptionAlreadyAddedToCart$(this.product.variantId, this.selectedSubscription?.id);
  }

  public ngOnInit(): void {
    this.addToCart$
      .pipe(
        tap((): void => {
          this.addedToCart$.next(true);
          this.cartService.addToCart(this.product, this.selectedSubscription?.id);
        }),
        switchMap(() =>
          this.cart$.pipe(
            skip(1),
            distinctUntilKeyChanged('itemCount'),
            take(1),
            tap((cart) => {
              this.cartService.openCart();
            })
          )
        ),
        untilDestroyed(this)
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    this.dialogRef?.dismiss();
  }

  public onAddToCartClick(event: Event): void {
    event.preventDefault();
    this.addToCart$.next();
  }

  public onNotifyMeClick(event: Event): void {
    event.preventDefault();
    // TODO: Inject the service in the constructor if needed again.
    // this.inventoryNotificationService.openNotificationDialogOrBottomSheet(this.product);
  }
}
