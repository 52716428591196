import { inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LanguageService } from '@core/modules/language/language.service';

@Injectable()
export class ProductLanguageInterceptor implements HttpInterceptor {
  private readonly languageService = inject(LanguageService);

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (req.url.includes('products') || req.url.includes('orders') || req.url.includes('cart')) {
      const headers = req.headers;
      const newHeaders = headers.set('Accept-Language', this.languageService.currentLanguage$.getValue());
      return next.handle(req.clone({ headers: newHeaders }));
    }
    return next.handle(req);
  }
}
