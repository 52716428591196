import { DatoProductItem, DatoUniversalVideoSubtitle } from '@core/services/dato/interfaces/product.interface';
import { ProductItem } from '@shared/interfaces/product.interface';
import { DatoMedia } from '@core/services/dato/interfaces/media-block.interface';
import { AvailableSubscription } from '@dd/shop-client-sdk';
import { DatoSubscription, DatoSubscriptionLocalization } from '@core/services/dato/interfaces/subscription.interface';
import { Product } from '../product/product';
import { Media, Video } from '../media/media-block';
import { mapMediaBlock } from './map-media-block';
import { mapVideoSubtitleToTracks } from './map-video';

export const mapProductToProductItem = (productItem: DatoProductItem, product: Product): ProductItem => {
  // eslint-disable-next-line unicorn/better-regex
  const extractProductIdRegExp = new RegExp('[^/]+$');
  const productId = product.variantId.match(extractProductIdRegExp)![0];
  const firstMediaBlock = productItem.mediaBlockOne[0];
  const secondMediaBlock = productItem.mediaBlockTwo[0];
  const productItemUnboxingVideo = productItem.unboxingVideo[0];
  const extraDetailsBlock = productItem.extraDetailsBlock[0];
  const { crossSellingProducts: _, ...sourceProductItem } = productItem;

  return {
    ...sourceProductItem,
    sku: product.sku,
    inStock: !!product.quantityAvailable,
    handle: product.handle,
    quantityAvailable: product.quantityAvailable,
    variantId: product.variantId,
    price: product.price,
    originalPrice: product.originalPrice,
    sale: product.priceDifferencePercentage ?? null,
    images: mapMediaWithSubtitles(productItem.images, productItem.videoSubtitles ?? []),
    vendor: product.vendor,
    productType: product.productType,
    unboxingVideo: productItemUnboxingVideo,
    mediaBlockOne: firstMediaBlock ? mapMediaBlock(firstMediaBlock) : undefined,
    mediaBlockTwo: secondMediaBlock ? mapMediaBlock(secondMediaBlock) : undefined,
    whatDoWeTestTabTitle: productItem.whatDoWeTestTabTitle?.tabTitle,
    whatSInTheBoxTitle: productItem.whatSInTheBoxTitle?.tabTitle,
    hasSubscription: !!product.availableSubscriptions?.length,
    availableSubscriptions: product.availableSubscriptions
      ? mapSubscriptionToSubscriptionsTranslations(product.availableSubscriptions, productItem.subscriptionLocalization)
      : [],
    extraDetailsBlock,
    productId
  };
};

export const mapSubscriptionToSubscriptionsTranslations = (
  subscription: AvailableSubscription[],
  localizations: DatoSubscriptionLocalization
): AvailableSubscription[] => {
  const localizationsArray = localizations ? localizations.subscriptions : [];
  return subscription.map((sub) => mapSubscriptionToSubscriptionTranslation(sub, localizationsArray));
};

export const mapSubscriptionToSubscriptionTranslation = (
  subscription: AvailableSubscription,
  localizations: DatoSubscription[]
): AvailableSubscription => {
  const translation = localizations.find((loc) => {
    return subscription.id.includes(loc.subscriptionId);
  });

  return {
    ...subscription,
    name: translation ? translation.translations[0].translation : subscription.name
  };
};

export const legacyMapProductToProductItem = (productItem: ProductItem, product: Product): ProductItem => {
  // eslint-disable-next-line unicorn/better-regex
  const extractProductIdRegExp = new RegExp('[^/]+$');
  const productId = product.variantId.match(extractProductIdRegExp)![0];

  return {
    ...productItem,
    inStock: !!product.quantityAvailable,
    handle: product.handle,
    quantityAvailable: product.quantityAvailable,
    variantId: product.variantId,
    price: product.price,
    originalPrice: product.originalPrice,
    sale: product.priceDifferencePercentage ?? null,
    images: productItem.images,
    vendor: product.vendor,
    productType: product.productType,
    productId
  };
};

export const mapProductsToProductItems = (productItems: DatoProductItem[], products: Product[]): ProductItem[] => {
  return products
    .filter((product) => productItems.some((item) => product.handle === item.sku))
    .map((product) => {
      const productItem = productItems.find((item) => product.handle === item.sku)!;
      return mapProductToProductItem(productItem, product);
    });
};

export const mapMediaWithSubtitles = (productSlideshow: DatoMedia[], subtitles: DatoUniversalVideoSubtitle[]): Media[] => {
  return productSlideshow.map((productMedia) => {
    const video = getVideoFromSource(productMedia, subtitles);
    return {
      ...productMedia,
      thumbnailUrl: productMedia.videoThumbnail?.thumbnailUrl,
      video
    };
  });
};

export const mapProductHandlesToName = (products: Product[], productItems: ProductItem[]): Product[] => {
  return products.map((product) => {
    const productItem = productItems.find((item) => product.handle === item.sku);
    return mapProductHandleToName(product, productItem);
  });
};

export const mapProductHandleToName = (product: Product, productItem?: ProductItem): Product => {
  return {
    ...product,
    handle: productItem?.title ?? product.handle
  };
};

const getVideoFromSource = (productMedia: DatoMedia, subtitles: DatoUniversalVideoSubtitle[]): Video | undefined => {
  const videoWithSubtitles = subtitles.find((subtitle) => subtitle.video.url === productMedia.url);
  if (videoWithSubtitles) {
    return {
      sources: [
        {
          url: videoWithSubtitles.video.url,
          type: `video/${videoWithSubtitles.video.format}`
        }
      ],
      videoSubtitles: mapVideoSubtitleToTracks(videoWithSubtitles.subtitles)
    };
  } else if (productMedia.videoThumbnail) {
    return {
      sources: [
        {
          url: productMedia.url,
          type: `video/${productMedia.format}`
        }
      ],
      videoSubtitles: []
    };
  }
  return undefined;
};
