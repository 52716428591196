/* eslint-disable unicorn/no-abusive-eslint-disable */
import { Inject, Injectable } from '@angular/core';
import {
  ApolloClient,
  createHttpLink,
  DocumentNode,
  FieldFunctionOptions,
  InMemoryCache,
  OperationVariables,
  QueryOptions
} from '@apollo/client';
import { DatoConfigurationParameters } from '@core/services/dato/dato-configuration';
import { setContext } from '@apollo/client/link/context';
import possibleTypes from '@config/dato-possible-types.json';
import { from, map, Observable } from 'rxjs';
import { TypePolicies } from '@apollo/client/cache';
import { CONFIG_TOKEN } from '../../../../../config-token';

@Injectable({
  providedIn: 'root'
})
export class DatoService {
  private client!: ApolloClient<object>;

  constructor(@Inject(CONFIG_TOKEN) private readonly config: DatoConfigurationParameters) {
    this.setupApollo();
  }

  /* eslint-disable-next-line */
  public get$<T = any>(query: DocumentNode, disableCache = false, variables?: OperationVariables): Observable<T> {
    const queryOptions: QueryOptions = {
      query,
      variables
    };

    if (disableCache) {
      queryOptions.fetchPolicy = 'no-cache';
    }

    return from(this.client.query<T>(queryOptions)).pipe(map((response) => response.data));
  }

  private setupApollo(): void {
    const token = this.config.datoReadToken;
    const httpLink =
      typeof window === 'undefined'
        ? createHttpLink({
            uri: this.config.datoUrl,
            fetch
          })
        : createHttpLink({
            uri: this.config.datoUrl
          });
    const authLink = setContext((_, { headers }) => {
      return {
        headers: Object.assign(headers || {}, {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`
        })
      };
    });

    // eslint-disable-next-line unicorn/consistent-function-scoping
    const getLocaleForCaching = (currentValue: string, { variables }: FieldFunctionOptions): string => {
      return currentValue ?? variables?.locale;
    };

    const typePolicies: TypePolicies = {
      Query: {
        fields: {
          product: {
            read: (existing, { args, toReference }) => {
              const sku =
                args?.filter.sku?.eq ||
                args?.filter.slug?.eq ||
                args?.filter?.OR?.find((filter: Record<string, unknown>) => {
                  return Object.keys(filter).includes('thirdPartyProducts');
                }).any?.thirdPartyProductBlock?.thirdPartySku?.eq ||
                args?.filter?.OR?.find((filter: Record<string, unknown>) => {
                  return Object.keys(filter).includes('slug');
                })?.eq ||
                args?.filter?.OR?.find((filter: Record<string, unknown>) => {
                  return Object.keys(filter).includes('sku');
                })?.eq ||
                '';
              return toReference({
                __typename: 'ProductRecord',
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                sku,
                locale: args?.locale
              });
            }
          }
        }
      },
      FaqRecord: {
        keyFields: ['title', 'content']
      },
      ThirdPartyProductBlockRecord: {
        keyFields: ['thirdPartySku']
      },
      SubscriptionLocalizationBlockRecord: {
        keyFields: ['locale', 'id'],
        fields: {
          locale: {
            read: getLocaleForCaching
          }
        }
      },
      ProductRecord: {
        keyFields: ['locale', 'sku'],
        fields: {
          locale: {
            read: getLocaleForCaching
          }
        }
      },
      UserTestimonialRecord: {
        keyFields: ['title', 'content']
      },
      BiomarkerRecord: {
        keyFields: ['name', 'description']
      },
      ProductFilterMenuRecord: {
        keyFields: ['id', 'locale'],
        fields: {
          locale: {
            read: getLocaleForCaching
          }
        }
      },
      ProductFilterRecord: {
        keyFields: ['id', 'locale'],
        fields: {
          locale: {
            read: getLocaleForCaching
          }
        }
      },
      ProductFilterGroupRecord: {
        keyFields: ['id', 'locale'],
        fields: {
          locale: {
            read: getLocaleForCaching
          }
        }
      },
      ProductFilterTypeRecord: {
        keyFields: ['id', 'locale'],
        fields: {
          locale: {
            read: getLocaleForCaching
          }
        }
      },
      PrivacyPolicyPageRecord: {
        keyFields: ['id', 'locale'],
        fields: {
          locale: {
            read: getLocaleForCaching
          }
        }
      },
      MediaBlockRecord: {
        keyFields: ['id', 'locale'],
        fields: {
          locale: {
            read: getLocaleForCaching
          }
        }
      },
      ArticleRecord: {
        keyFields: ['title', 'locale'],
        fields: {
          locale: {
            read: getLocaleForCaching
          }
        }
      },
      FaqCategoryRecord: {
        keyFields: ['id', 'locale'],
        fields: {
          locale: {
            read: getLocaleForCaching
          }
        }
      },
      FooterRecord: {
        keyFields: ['id', 'locale'],
        fields: {
          locale: {
            read: getLocaleForCaching
          }
        }
      }
    };

    this.client = new ApolloClient({
      // eslint-disable-next-line unicorn/prefer-spread
      link: authLink.concat(httpLink),
      cache: new InMemoryCache({
        possibleTypes,
        typePolicies: typePolicies as TypePolicies
      })
    });
  }
}
